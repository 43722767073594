
.wpb_wrapper {
    max-width: 100%;
    position: relative;
}
.text_footer{
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
    /* margin-left: 8.33333%; */
    padding: 4px 0 4px;
    -webkit-box-align: center;
    align-items: center;
}
@media screen and (max-width: 480px) {
    .text_footer{
        max-width: 100%;
        flex-direction: column;
    }
    .lien{
        max-width: 70%!important;
        margin-bottom: 10px;
    }
    .logo{
        margin-bottom: 10px;
    }
    .copyright{
        margin-bottom: 10px;
    }
    .social{
        margin-bottom: 10px;
    }
  }
.logo{
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: right;
}
.logo_black_footer {
    vertical-align: middle;
    border-style: none;
    height: auto;
    opacity: 1;
    transition: opacity 400ms;
    transition-delay: 0ms;
    width: 80%;
    max-width: 90px;
}
.copyright{
    position: relative;
    width: 100%;
    min-height: 1px;
    -webkit-box-flex: 0;
    flex: 0 0 16.66667%;
    max-width: 60%;
    padding: 0;
}
.copyright p{
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: century_gothic;
    font-size: 15px;
    margin: 0;
    max-width: 100%;
}
.lien{
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    flex: 0 0 58.33333%;
    max-width: 50%;
    padding-right: 0;
}
.lien p{
    margin: 0;
    max-width: 100%;
    font-family: century_gothic;
    color: black;
    font-size: 15px;
    -webkit-box-pack: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}
.lien p span{
    margin: 0 8px;
}
.lien p a{
    background-color: transparent;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.60);
    display: inline-block;
    font-size: 15px;
}
#lang_choice_1 {
    margin: 0;
    font-family: inherit;
    line-height: inherit;
    text-transform: none;
    color: rgba(0, 0, 0, 0.60);
    display: inline-block;
    font-size: 15px;
    border: none;
}
.social{
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    -webkit-box-flex: 0;
    max-width: 8.33333%;
}
.list_social {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
}
.list_social li{
    margin-right: 5px;
}
.list_social li a{
    text-decoration: none;
    background-color: transparent;
    color: rgba(0,0,0,0.60);
}
.fab {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: Font Awesome\ 5 Brands;
}